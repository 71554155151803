.RecoverPassword{

  .login-form-components{
    display: flex;
    flex-direction: column;
    width: 506px;
    align-items: center;
    .to-login-text{
      margin: 14px 0;
      font-family: Verdana, Geneva, sans-serif;

      color: #454445;
    }
    .login-form{
      display: flex;
      flex-direction: column;
      input[type='password']{
        width: 480px;
        height: 48px;
        margin: 14px 0;
        padding: 0 10px;
        font-family: Verdana, Geneva, sans-serif;

        border-radius: 6px;
        border: solid 1px rgba(112, 112, 112, 0.4);

        color: #212529;
        opacity: 0.4;
      }
      input[type='password']::placeholder{
        text-align: center;
      }
      input[type='password']:focus::placeholder{
        color: transparent;
      }
      .is-invalid{
        border: solid 1px rgb(255, 24, 24) !important;
      }
      .error-message{
        color: red;
      }
    }
    .submit-button{
      width: 480px;
      height: 48px;
      margin: 14px 0;
      padding: 0 10px;
      border-radius: 6px;

      background-color: #fe961d;
    }

  }

}
