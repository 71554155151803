.ProductCard {
  width: 270px;
  margin: 30px 10px;

  img{
    width: 270px;
    max-width: 100%;
  }


  .product-description-container{
    margin: 35px 0 10px 0;
    line-height: 1.67em;
    font-size: 18px;
    .product-title{
      min-height: 60px;
      font-weight: 600;
      color: #1e1e1e;
    }
    .product-description{
      min-height: 60px;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
      color: #1e1e1e;
      font-family: Verdana, Geneva, sans-serif;
    }
    .product-estimate{
      color: rgba(30, 30, 30, 0.6);
    }
  }

  .price-container{
    position: relative;
    height: 32px;
    .price-text{
      font-size: 16px;
      color: rgba(30, 30, 30, 0.6);
      position: absolute;
      bottom: 5px;
      left: 0;
      font-family: Verdana, Geneva, sans-serif;

    }
    .price-value{
      font-size: 22px;
      font-weight: 900;
      color: #1e1e1e;
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: Verdana, Geneva, sans-serif;
    }
    .higher-price{
      font-size: 19px;
    }
  }

  .product-details-button{
    margin: 15px 0;
  }


}
