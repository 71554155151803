.product-kit-title {
  color: #565556;
  font-size: 32px;
  padding-left: 62px;

  @media screen and (max-width: 542px) {
    font-size: 26px;
  }
}

.ProductDetailsContainer {
  background-color: #fff;
  border-radius: 30px;
  width: 100%;
  padding: 0px 53px 43px 62px;
  position: relative;
  margin-top: 230px !important;

  @media screen and (max-width: 1234px) {
    padding: 0 10% 43px;
  }

  .ProductDetails {
    margin-top: -213px;
    color: #454445;
    justify-content: space-between;
    display: flex;

    &::before {
      z-index: -1 !important;
      border-radius: 25px !important;
      height: 100%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px rgba(112, 112, 112, 0.25);
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
