.Footer {
  width: 100%;
  border: 1px solid #666;
  display: flex;
  align-items: center;
  color: rgb(255, 151, 26);
  background-color: #404041;
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  span, a {
    font-size: 16px;
    font-family: 'Verdana';
    line-height: 1.5;
    color: #ffffff;
  }
  .top {
    margin: 60px auto 98px;
    padding: 0 53px;
    height: 231.8px;

    .left{
      display: flex;
      flex-direction: column;
      gap: 15px;

      span {
        width: 80%;
        font-size: 12px;
      }

      .footerLogo {
        width: 254px;
        height: 62px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .socialMediaList {
        display: flex;
        margin: 0 0 25.6px;
        gap: 20px;

        .icon {
          margin-right: 35.4px;
          width: 22.8px;
          height: 22.8px;
        }
      }
    }
  }
  hr {
    display: none;
  }
  .footer-bottom {
    padding: 0 53px;
    min-height: 95px;
    .copyright {
      letter-spacing: 1.6px;
      line-height: 1.06;
    }
    .politics {
      display: flex;
      a {
        margin-left: 71.3px;
        height: 21px;
        text-decoration: underline;
        line-height: 1.44;
        letter-spacing: 1.6px;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
    
@media (max-width: 900px) {
  .Footer {
    height: fit-content;
    align-items: flex-start;
    & > div {
      display: flex;
      flex-direction: column;
      width: 80%;
    }
    span, a {
      font-size: 14px;
      font-family: 'Verdana';
      line-height: 1.5;
      color: #ffffff;
    }

    .top {
      height: auto;
      margin-bottom: 30px;
      .left{
        margin-bottom: 50px;
        .footerLogo {
          width: 212px;
          height: 62px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .socialMediaList {
          display: flex;
          text-align: center;
          .icon {
            margin-right: 35.4px;
            width: 22.8px;
            height: 22.8px;
          }
        }
      }
    }
    hr {
      display: none;
      width: 80%;
      height: 1px;
      background-color: #fff;
      margin-left: 39px;
    }
    .footer-bottom {
      flex-direction: column-reverse;
      height: fit-content;
      .copyright {
        margin: 15px 0 20px;
      }
      .politics {
        display: flex;
        flex-direction: column;
        width: 100%;
        a {
          margin: 0 0 12px;
          text-decoration: underline;
          letter-spacing: 1.4px;
          &:hover {
            color: inherit;
          }
          @media screen and (max-width: 900px) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}