.SalesKitList {
    width: 100%;
    .Container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            color: rgb(255, 151, 26);
        }
    }
}
