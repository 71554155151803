.accordionToggle {
  width: 100%;
  height: 129.5px;
  display: flex;
  align-items: center;
  box-shadow: none;
  border: none;
  background-color: #FFF;
  font-size: 32px;
  font-family: 'Lato';
  font-weight: bold;
  color: #1e1e1e;
  span {
    color: #1e1e1e;
    bottom: 0;
  }
  .arrowSpan {
    font-size: 20px;
    margin: 0 10px;
    transform: rotate(0deg);
    transition: transform .2s ease-in;
  }
  .opened {
    transition: transform .2s ease-in;
    transform: rotate(180deg);
  }
}
.download-link {
  cursor: pointer;

  @media screen and (max-width: 1234px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.download-link:hover {
  color: #f18f28;
}