.ProductDetails {
  .ValuesDetails {
    margin-top: 33px;
    .kitTitle {
      display: block;
      max-width: 602px;
      font-size: 42px;
      line-height: 1.43;
      color: #1e1e1e;

      @media screen and (max-width: 1050px) {
        max-width: unset;
      }
      @media screen and (max-width: 1234px) {
        text-align: center;
      }
    }
    .kitInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 531px;
      margin: 24px 178px 40px 0;
      font-size: 18px;
      line-height: 2;
      font-family: Verdana, Geneva, sans-serif;

      @media screen and (max-width: 1050px) {
        max-width: unset;
      }
      @media screen and (max-width: 1234px) {
        text-align: center;
      }
    }
    .detailsTable {
      .tableRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 23px;
        height: 53.25px;
        border: solid 1px rgba(112, 112, 112, 0.25);;
        border-bottom: none;
      }
      .tableRow:first-child {
        border-radius: 10px 10px 0 0;
      }
      .tableRow:last-child {
        border-bottom: solid 1px rgba(112, 112, 112, 0.25);
        font-size: 22px;
        border-radius: 0 0 10px 10px
      }
      .totalResume {
        font-size: 16px;
        > span:first-child  {
          font-size: 16px;

        }
        .breakLine {
          display: block;
          text-align: right;
        }
        .total-value{
          font-size: 22px !important;
          margin-right: 5px;
          span:last-child{
            font-size: 16px;
            font-weight: 500;
          }
        }
        .bold {
          font-weight: bold;
        }
      }
    }
    
    @media screen and (max-width: 1050px) {
      display: flex;
      flex-direction: column;
      .total-value{
        font-size: 16px !important;
      }
    }
  }
  .productImageAside, .productImageInside {
    width: 100%;
    max-width: 443px;
    align-self: center;
  }

  .productImageAside {
    @media screen and (max-width: 1050px) {
      display: none;
    }
  }

  .productImageInside {
    display: none;

    @media screen and (max-width: 1050px) {
      display: block;
    }
  }

  @media screen and (max-width: 1050px) {
    justify-content: center !important;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}