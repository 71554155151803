$max-width: 1050px;
$max-width-secondary: 1245px;

.requestForm {
  .show {
    transition: all 0.2s ease-in-out;
  }
  .cep-text,
  .span {
    color: #454445 !important;
  }
  .dropdown-toggle:focus,
  .inputMask:focus,
  .currencyInput:focus,
  .form-check-input:focus {
    box-shadow: none !important;
  }
  .dropdown {
    margin: 15px 0 34px;
    font-family: Verdana, Geneva, sans-serif;

    .btn-primary.dropdown-toggle{
      background-color: transparent;
      border-color: #cbcbcb;
      color: #212529;
    }

    .dropdownToggle {
      color:#212529;
      height: 51px;
      width: 610.5px;
      border-radius: 6px;
      border: 1px solid #cbcbcb;
      background-color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &:disabled{
        background-color: #e9ecef;
      }

      @media screen and (max-width: $max-width) {
        height: auto;
        min-height: 50px;
        width: 100%;
        font-size: 15px;
        display: inline;
        white-space: pre-line;
        overflow: visible;
        word-wrap: break-word;
        text-align: center;
        padding: 5px;
        &::after {
          background-color: white;
          position: absolute;
          right: 0;
          border: none;
        }
      }
    }

    .dropdownMenu,
    .dropdownItem {
      .paymentItem {
        display: grid;
        grid-template-columns: 40% 60%;
      }

      .emptyPaymentItem {
        display: flex;
      }

      @media screen and (max-width: $max-width) {
        height: auto;
        width: 100%;
        font-size: 15px;
        display: inline;
        white-space: pre-line;
        overflow: visible;
        word-wrap: break-word;
        text-align: center;
        padding: 5px;
      }
    }

    .dropdownItem {
      max-width: 100%;
      height: auto;
      white-space: pre-line;
      overflow: visible;
      word-wrap: break-word;
    }

    .dropdownMenu {
      max-height: 300px;
      width: 610.5px;
      overflow-y: auto;

      @media screen and (max-width: $max-width) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    .dropdownItemSelected {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: $max-width) {
        max-width: 100%;
        height: auto;
        white-space: normal;
        overflow: visible;
        word-wrap: break-word;
      }
    }
  }

  .installationType {
    .installationSelect {
      margin: 30.5px 0 34px;
    }

    @media screen and (max-width: $max-width) {
      text-align: center;
    }
  }

  .serviceValue {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 10px;
      @media screen and (max-width: $max-width) {
        margin-right: 0;
      }
    }

    .currencyInput {
      margin: 15px 0 34px;
      width: 400px;
      height: 51px;
      padding-left: 15px;
      border-radius: 6px;

      @media screen and (max-width: $max-width) {
        width: 100%;
      }
    }

    .insuranceInput {
      width: 252.2px;

      @media screen and (max-width: $max-width) {
        width: 100%;
      }
    }

    @media screen and (max-width: $max-width) {
      width: 100%;
      text-align: center;
    }
  }

  .valuesGroup {
    display: flex;
    flex-direction: row;
    //align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: $max-width) {
      flex-direction: column;
      align-items: center;
    }

    .shipping {
      margin-left: 43.8px;

      @media screen and (max-width: $max-width) {
        width: 100%;
        margin-left: 0;
        text-align: center;
      }

      & > div {
        margin: 15px 0 34px;
      }

      div {
        display: flex;
        justify-content: flex-start;
        
        .product-details-button {
          height: 51px;
          width: 186px;
          background-color: #fe961d !important;

          @media screen and (max-width: $max-width) {
            width: 100%;
          }
        }

        .inputMask {
          margin-right: 44.8px;
          height: 51px;
          width: 210px;
          border-radius: 6px;
          padding-left: 33px;
          border: solid 1px rgb(203, 203, 203);

          @media screen and (max-width: $max-width) {
            width: 100%;
            margin-right: 0;
          }
        }

        @media screen and (max-width: $max-width) {
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }

      .shippingInfo {
        font-family: Verdana, Geneva, sans-serif;
        width: 100%;
        height: 51px;
        border-radius: 25px;
        justify-content: space-between;
        align-items: center;
        padding: 0 23px;
        border: 1px solid #cbcbcb;
        margin: 0;
        display: none;
        max-width: 440px;

        @media screen and (max-width: $max-width) {
          height: auto;
          min-height: 50px;
          max-width: 100%;
        }
      }

      .show {
        display: flex;
      }

      .shipping-fields {
        @media screen and (max-width: $max-width) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      @media screen and (max-width: $max-width-secondary) {
        margin-left: 0;
      }
    }
  }

  .paymentType {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: $max-width) {
        justify-content: center;
        flex-direction: column;
      }
    }

    .paymentGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      div {
        margin: 15px 0;
      }

      @media screen and (max-width: $max-width) {
        flex-direction: column;
        > div,
        .dropdown {
          width: 100%;

          .product-details-button {
            width: 100%;
          }
        }
      }

      .product-details-button {
        width: 279px;
        margin-left: 44.8px;
        height: 51px;

        @media screen and (max-width: $max-width) {
          margin-left: 0;
        }
      }
    }

    .shippingPayment {
      width: 610.2px;

      .shippingRadio {
        display: flex;
        flex-direction: column;

        > :nth-child(2) {
          border: 1px solid #cbcbcb;
          border-radius: 26px 26px 0 0;
          border-bottom: none;
        }

        > :nth-child(3) {
          border-radius: 0 0 26px 26px;
          border: 1px solid #cbcbcb;
        }

        .form-check {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          height: 51px;
          margin: 0;
          padding: 0 23px;

          .form-check-input:checked {
            background-color: orange;
            border-color: orange;
          }
        }
      }
    }

    @media screen and (max-width: $max-width) {
      text-align: center;
    }
  }

  .error {
    border: 1px solid red !important;
  }

  .cep-invalid-label {
    font-size: 14px;
  }

  @media screen and (max-width: 1024px) {
    .valuesGroup {
      flex-direction: column !important;
 
      .shipping {
        margin: 0;
      }
    }

    .paymentGroup {
      align-items: flex-start !important;
      flex-direction: column !important;
      
      button {
        margin: 0 !important;
      }
    }
  }

  #accordionListItems {
    width: 100%;
    }
  
  @media screen and (max-width: 1024px) {
    .accordionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
