$darker-gray: #404041;
$white: #fff;

.Header-Mobile {
  display: none;
}
@mixin loginIconDefaultStyle {
  display: flex;
  align-items: center;
  .icon {
    margin: 0 12.2px 0 27.4px;
  }
  span {
    font-size: 16px;
  }
  @media (max-width: 992px) {
    margin: 30px 0 0 50px;
    font-size: 20px;

    .icon {
      margin: 0 12.2px 0 0;
      height: 100%;
    }
  }
}
.Header {
  background-color: $darker-gray;
  height: 179px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  box-shadow: 0 3px 6px 0 #00000029;

  hr {
    background-color: #707070;
    margin: 0;
  }

  & > div {
    margin: auto;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 65px;
    .left {
      display: flex;
      flex-direction: column;
      .HeaderLogo {
        width: 254px;
        height: 60px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      color: $white;

      .icon {
        width: 28.5px;
        height: 22.6px;
      }
      .loginIcon {
        @include loginIconDefaultStyle();
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .bottom {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    padding: 0 65px;

    .left {
      display: flex;
      gap: 15px;

      a {
        font-size: 16px;
        position: relative;
        text-decoration: none;
        color: $white;
        text-align: center;
        &.active::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 10px;
          background-color: #f17a17;
          bottom: -25px;
          left: 0;
          border-radius: 5px 5px 0 0;
        }
      }
    }
    .right {
      display: flex;
      gap: 20px;
      a {
        color: $white;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
      .icon {
        width: 22.8px;
        height: 22.8px;
      }
    }
  }
}

@media (max-width: 1064px) {
  .Header {
    display: none;
  }

  .Header-Mobile {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
    background-color: $darker-gray;
    .items-menu {
      color: $darker-gray;
    }

    .HeaderLogo {
      width: 135px;
      margin-left: 39px;
    }
    & > div {
      display: flex;
      gap: 24px;
      margin-right: 38px;
      .menu-icon {
        cursor: pointer;
        color: $white;
        font-size: 25px;
      }
      .cart-icon {
        font-size: 25px;
      }
    }
  }
  .menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    margin-left: -103%;
    display: block;
    background-color: rgba(50, 50, 50, 0.7);

    .mobile-menu {
      transition: margin-left 0.5s ease-in;
      margin-left: -120%;
      overflow: auto;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 85vw;
      background-color: $white;
      z-index: 101;
      padding-top: 30px;
      display: flex;
      flex-direction: column;

      .items-menu {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 50px 30px;

        a {
          text-decoration: none;
          color: $darker-gray;
        }
      }

      box-shadow: 0 0 4px #666;
      .mini-logo {
        width: 200px;
        margin: 0 30px;
      }
      .social-media {
        margin: 0 30px;
        gap: 0;

        a {
          color: $darker-gray;
          text-decoration: none;
          margin-bottom: 20px;
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }
      .loginIcon {
        @include loginIconDefaultStyle();
        margin: 10px 30px !important;
        color: $darker-gray;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  .open {
    width: 100vw !important;
    margin-left: 0;
    .mobile-menu {
      margin-left: 0;
    }
  }
}