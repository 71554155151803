.ProductProposalInfo {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  padding: 0 !important;

  .Details {
    display: flex;
    justify-content: space-between;
    .ValuesDetails {
      margin-top: 33px;
      .proposalID {
        font-size: 40px;
        line-height: 1;
        color: #f17a17;
      }
      .kitTitle {
        display: block;
        max-width: 602px;
        font-size: 26px;
        line-height: 1.43;
        color: #1e1e1e;
        margin-bottom: 43px;
      }
      .kitInfo {
        display: flex;
        flex-direction: column;
        max-width: 531px;
        margin: 24px 178px 40px 0;
        font-size: 16px;
        line-height: 2;
      }
    .subTotal {
      margin-top: 43px;
      font-size: 32px;
      line-height: 1.43;
      color: #1e1e1e;
    }

    .productImage {
      width: 443px;
      height: 354px;
    }
  }
  }
  .priceDetail {
    margin-top: 27px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .headerText {
      font-size: 16px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      > span:nth-child(2){
        font-size: 16px;
      }
    }

    .simpleValues {
      font-size: 25px;
      .postalCode {
        font-weight: bold;
        font-size: 16px;
      }

    }

    .total {
      color: #fe961d;
    }

  }
.productImage {
  width: 443px;
  height: 354px;
}
}

@media screen and (max-width: 768px) {

  .ProductProposalInfo {
    .productImage{
      height: 100% !important;
      width: 100%!important;
    }

    .Details{
      display: flex;
      flex-direction: column;
      width: 100%;
      .ValuesDetails {
        margin-top: 20px !important;
        span {
          font-size: 22px !important;
        }
      .kitInfo {
        span {
          font-size: 12px !important;
          width: 300px;
        }
      }
      .kitTitle {
        margin-bottom: 20px !important;
      }
      }
    }
  }
  .priceDetail {
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerText {
      margin-top: 15px;
    }
  }
}