.proposalDetail {
  padding: 0 !important;

  .span {
    color: #454445 !important;
  }
  
  .paymentInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;

    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      span:last-child {
        font-size: 27px;
      }
    }
    .TotalRow {
      display: flex;
      flex-direction: row;
      gap: 60px;
      .column {
        display: flex;
        flex-direction: column;
        span:last-child {
          font-size: 27px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .proposalDetail {
    display: flex;
    flex-direction: column;
    .paymentInfo {
      flex-direction: column;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 150px;
      justify-content: flex-start;
      align-items: flex-start;
      .TotalRow {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .toggles-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 190px;
  }
  
}