.LoginPage{

  .login-form-components{
    display: flex;
    flex-direction: column;
    max-width: 506px;
    width: 100%;
    align-items: center;
    padding: 30px;
    .to-login-text{
      margin: 14px 0;
      text-align: center;
      font-family: Verdana, Geneva, sans-serif;
      color: #454445;
    }
    .login-form{
      display: flex;
      flex-direction: column;
      width: 100%;
      input[type='text'], input[type='password']{
        max-width: 480px;
        width: 100%;
        height: 48px;
        margin: 14px 0;
        padding: 0 10px;
        font-family: Verdana, Geneva, sans-serif;

        border-radius: 6px;
        border: solid 1px rgba(112, 112, 112, 0.4);

        color: #212529;
        opacity: 0.4;
      }
      input[type='text']::placeholder{
        text-align: center;
      }
      input[type='text']:focus::placeholder{
        color: transparent;
      }
      input[type='password']::placeholder{
        text-align: center;
      }
      input[type='password']:focus::placeholder{
        color: transparent;
      }
      .is-invalid{
        border: solid 1px rgb(255, 24, 24) !important;
      }
      .error-message{
        color: red;
      }
      .submit-button{
        max-width: 480px;
        width: 100%;
        height: 48px;
        margin: 14px 0;
        padding: 0 10px;
        border-radius: 6px;

        background-color: #fe961d;
      }
      .login-information{
        margin: 10px 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        .keep-login{
          display: flex;
          flex-direction: row;
          #keep-login-check{
            margin-right: 24px;
            width: 24px;
            height: 24px;

            border: solid 1px rgba(112, 112, 112, 0.4);
          }
        }
        .keep-login-text{
          color: #575657;
        }
        .forgot-password{
          color: #575657;

          text-decoration: none;
        }
        .cadastro{
          color: #575657;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
    .policy-text{
      text-align: center;
      color: #575657;

      margin: 17px 0;
      a{
        color: #575657;
      }
    }
  }

}
