$max-width: 1234px;

.ProductDetailsContainerProposta {
  background-color: #fff;
  border-radius: 30px;
  width: 100%;
  position: relative;

  
  .ProductProposalInfo {
    color: #454445;
    justify-content: space-between;
    display: flex;
  }

  .ProductDetails {
    margin-top: -213px;
    color: #454445;
    justify-content: space-between;
    display: flex;

    &::before {
      z-index: -1 !important;
      border-radius: 25px !important;
      height: 100%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px rgba(112, 112, 112, 0.25);
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  @media screen and (max-width: $max-width) {
    padding: 0px 10% 43px;
    height: 100%;

    hr {
      display: none;
    }
  }
}

