$max-width: 1234px;

.accordionToggle {
  width: 100%;
  height: 129.5px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  box-shadow: none;
  border: none;
  background-color: #FFF;
  font-size: 32px;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: bold;

  .show {
    width: 100%;
  }
  
  .arrowSpan {
    font-size: 20px;
    margin: 0 10px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in;
  }

  .opened {
    transition: transform 0.2s ease-in;
    transform: rotate(180deg);
  }

  @media screen and (max-width: $max-width) {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
}

.itemRow {
  width: 933.2px;
  height: 52px;
  display: grid;
  grid-template-columns: 85% 15%;
  border: 1px solid rgb(203, 203, 203);

  &:nth-child(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;
  }
  
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  span:nth-child(1) {
    border-right: 1px solid rgb(203, 203, 203);
  }

  span {
    display: flex;
    align-items: center;
    padding-left: 23px;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 16px;
    line-height: 2;
    text-align: left;
    color: rgb(69, 68, 69);

    @media screen and (max-width: $max-width) {
      font-size: 15px;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .itemAmountHeader {
    @media screen and (max-width: $max-width) {
      padding-left: 14px;
      margin-right: 10px;
      justify-self: center;
    }
  }

  .itemAmountHeader:after {
    content: "Quantidade";
    
    @media screen and (max-width: $max-width) {
      content: "Qtd";
    }
  }

  .itemAmount {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0%;
  }
  
  @media screen and (max-width: $max-width) {
    height: auto;
    width: 100%;
  }
}

.darkRow {
  background-color: rgba(112, 112, 112, 0.25);
}
