.ListFilters {
  padding: 33px 0;
  color: #565556;

  .filters-container{
    margin: 0 50px;
    .filters-form{
      display: flex;
      justify-content: space-between;

      .filter-text{
        font-family: Verdana, 'Geneva',  sans-serif;
      }

      .filter-select{
        margin-left: 30px;
      }
    }

    .filters-form > div {
      display: flex;
    }

    .active-filters{
      margin-top: 20px;
      font-weight: 500;
      span{
        font-weight: 400;

      }
    }
  }
}

.create-kit{
  margin:10px 0 0 29%;
  
    button {
      width: 100%;
      font-weight: normal;
    }
}

@media only screen and (max-width: 1000px) {
  .ListFilters {
    .filters-container{
      margin: 0 60px;
      .filters-form{
        display: flex;
        flex-direction: column;
        gap: 15px;

        > div {
          display: flex;
          flex-direction: column;
          gap: 5px;
          
          div {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
