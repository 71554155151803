.ListProducts {
  .list-title-container {
    border-top: 1px solid rgba(112, 112, 112, 0.25);
    padding: 16px 0 80px 0;
  }

  .list-title {
    margin: 0 60px;
    color: #565556;
    font-size: 32px;
  }

  .products-container {
    margin-top: -70px;
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    width: 100%;
    padding: 40px 53px 43px 62px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(112, 112, 112, 0.25);

    .products-title {
      color: rgba(30, 30, 30, 0.6);
      font-size: 16px;
      line-height: 2;
      font-family: Verdana, Geneva, sans-serif;
      color: #454445;
      max-width: 950px;
    }

    .products-items {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 270px);
      justify-content: space-around;
      min-height: 400px;

      &--empty {
        width: 100%;
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--text {
          font-weight: 600;
        }
      }
    }

    .load-more-shadow {
      margin-top: -300px;
      background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
      height: 280px;
      width: 100%;
      position: relative;
      left: 5px;

      .load-more {
        background-color: #ffffff;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 65px;

        .load-more-button {
          max-width: 420px;
          margin-top: 15px;
        }
      }
    }
  }
}
