.LoginWrapper{
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  position: relative;

  .title{
    display: flex;
    flex-direction: column;
    position: absolute;
    color: white;
    height: 252px;
    width: 100%;

    .login-breadcrumb{
      color: #454445;
      height: 70px;
      width: 100%;
      border-bottom: 1px solid rgba(112, 112, 112, 0.25);

      display: flex;
      align-items: center;
      .breadcrumb-content{
        color: #454445;
        padding: 0 45px;
      }
      a {
        text-decoration: none;
        color: #454445;
      }
    }
    .account-access-message{
      color: #454445;
      margin: 16px 0 24px 0;
      padding: 0 45px;
      font-size: 43px;
      position: relative;
    }
  }

  .login-content{
    margin-top: 181px;
    padding: 26px 0 200px 0;
    background-color: #ffffff;
    border: solid 1px rgba(112, 112, 112, 0.25);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 50px 50px 0 0;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

  }
}

@media only screen and (max-width: 800px) {
.LoginWrapper {
   .title .account-access-message {
     margin: 16px 0 25px 0;
     padding: 0 45px;
     font-size: 27px;
   }
 }
}



