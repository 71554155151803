.ForgotPassword{

  .login-form-components{
    display: flex;
    flex-direction: column;
    max-width: 506px;
    width: 100%;
    align-items: stretch;
    padding: 30px;
    .to-login-text{
      margin: 14px 0;
      font-family: Verdana, Geneva, sans-serif;

      color: #454445;
    }
    .login-form{
      display: flex;
      flex-direction: column;
      width: 100%;
      input[type='text']{
        max-width: 480px;
        width: 100%;
        height: 48px;
        margin: 14px 0;
        padding: 0 10px;
        font-family: Verdana, Geneva, sans-serif;

        border-radius: 6px;
        border: solid 1px rgba(112, 112, 112, 0.4);

        color: #212529;
        opacity: 0.4;
      }
      input[type='text']::placeholder{
        text-align: center;
      }
      input[type='text']:focus::placeholder{
        color: transparent;
      }
      .is-invalid{
        border: solid 1px rgb(255, 24, 24) !important;
      }
      .error-message{
        color: red;
      }
    }
    .submit-button{
      max-width: 480px;
      width: 100%;
      height: 48px;
      margin: 14px 0;
      padding: 0 10px;
      border-radius: 6px;

      background-color: #fe961d;
    }

  }

}
