.login-modal{
  width: fit-content;
  margin: 0 auto;
  margin-top: calc(50vh - 204px);
  .modal-content{
    padding: 0px 122px;
    width: 982px;
    height: 407px;


    border-radius: 25px;
    box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.16);

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    .modal-close{
      position: absolute;
      right: 32px;
      top: 32px;
      width: 25px;
      height: 25px;
    }
    .modal-logo{
      width: 339.9px;
      height: 82.6px;
      margin: 3.5px 0;
    }
    .modal-text{
      font-size: 20px;
      opacity: 0.7;
      margin: 20px 0;

    }
    .redirect-button{
      width: 159px;
      height: 51px;
      margin: 20px 0;

      font-family: Verdana, Geneva, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 24px;
      background-color: #fe961d;
    }
  }
}
