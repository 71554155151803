.Spinner{
  position: relative;

  .spinner-overlay{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: wait;
    position: absolute;
    .spinner-icon{
      position: absolute;
      top: calc(50% - 25px);
    }
  }
}
