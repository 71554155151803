.CustomSelect{
  border-radius: 6px;
  border: 1px solid rgba(112, 112, 112, 0.25);
  padding-right: 10px;

  select{
    color: #454445;
    padding: 15px;
    border-radius: 6px;
    border: none;
    width: 100%;
  }
}
